@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'





























































.v-list-item.v-list-item--disabled
  .v-list-item__icon.v-icon
    color: inherit !important

  .v-list-item__subtitle
    color: inherit !important
