@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'


















































































































































































































.toolbar
  .v-btn--outlined, .v-btn--text
    height: 48px !important

.v-card__title
  &.section-title, .section-title
    // primary--text
    color: var(--v-primary-base) !important
    // text-button
    font-size: 0.875rem !important
    line-height: 2.25rem
    letter-spacing: 0.0892857143em !important
    font-family: "Roboto", sans-serif !important
    text-transform: uppercase !important
    // font-weight-bold
    font-weight: 700 !important
