@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'










































































.v-snack.v-snack--active
  @media #{map-get($display-breakpoints, 'xs-only')}
    padding-bottom: 22px !important
