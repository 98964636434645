@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'


















































































































































































































































































































































































































































.v-app-bar.v-app-bar--hide-shadow
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important

.v-toolbar__items.account
  @media #{map-get($display-breakpoints, 'xs-only')}
    height: 48px

.v-list-item.v-list-item--active
  &::before
    opacity: 0

  .v-list-item__subtitle
    color: inherit !important

.v-btn.no-overflow::v-deep >
  .v-btn__content
    width: 100%
